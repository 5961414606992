<template>
  <div class="activeAccount">
    <div class="account_content content_area">
      <img :src="successImg" alt v-if="showLink" />
      <img
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/error_img.png"
        v-else
        alt
      />
      <div v-if="showLink" class="tips_text">
        <div class="email">{{ routerVal.email }}</div>
        <p class="pageColor">{{ $t("activeSuccess") }}</p>
        <div class="divider-line">
          <el-divider>
            <span>{{ count }}&nbsp;</span>s
          </el-divider>
        </div>
      </div>
      <div v-else class="tips_text">
        <p class="dangerColor" v-if="errorcode == 1001">
          {{ $t("linkexpired") }}
        </p>
        <p class="dangerColor" v-else-if="errorcode == 1002">
          {{ $t("illegalLink") }}
        </p>
        <p class="dangerColor" v-else-if="errorcode == 1003">
          {{ $t("accountisActived") }}
        </p>
        <p class="dangerColor" v-else>{{ $t("systemBusy") }}</p>
        <br v-if="errorcode == 1003" />
        <p>
          <el-button type="default" @click="backHome">{{
            $t("backHome")
          }}</el-button>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { _decodeString } from "~/basePlugins/base64";

export default {
  data() {
    return {
      count: 5,
      showLink: false,  // 判断显示与隐藏
      user_id: "",
      routerVal: {},
      errorcode: "",
      successImg: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/success.png",
    };
  },
  mounted() {
    this.checkCode();
  },
  methods: {
    backHome() {
      this.$router.push("/");
    },
    backSign() {
      this.$router.push("/users/sign");
    },
    async checkCode() {
      let params;
      if (this.$route.query.parameter) {
        params = _decodeString(this.$route.query.parameter);
      } else {
        return;
      }
      this.routerVal = params;
      // console.log(this.routerVal)
      try {
        let result = await this.$store.dispatch("baseStore/baseSign_Verify", {
          type: params.type,
          code: params.code,
          email: params.email,
          source: params.source,
          user_id: params.user_id,
        });
        // 判断请求是否成功
        if (result.success) {
          // this.showLink 该为true
          this.showLink = true;
          this.backCount();
          let user_result = await this.$store.dispatch(
            "baseStore/baseSign_getUserInfo",
            {
              user_id: result.user_id,
            }
          );
          if (user_result.success) {
            this.user_id = user_result.data.id;
          }
        } else {
          this.showLink = false;
          this.errorcode = result.errorcode;
        }
      } catch (e) {
        this.showLink = false;
      }
    },
    backCount() {
      if (this.showLink) {
        this.count--;
        this.timer = setTimeout(() => {
          this.backCount();
        }, 1000);
        if (this.count == 0) {
          clearTimeout(this.timer);
          this.$router.push({
            path: "/basicInfo",
            query: {
              parameter: this._encode({
                need_type: "phone",
                user_id: this.user_id,
              }),
            },
          });
          this.timer = null;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.activeAccount {
  min-height: 800px;
  background: #ffffff;
  .account_content {
    text-align: center;
    padding-top: 150px;
    .tips_text {
      margin: 70px auto;
    }
    .email {
      font-size: 20px;
      color: #717171;
      margin-bottom: 15px;
      font-weight: bold;
      margin-top: -20px;
    }
    .pageColor,
    .dangerColor {
      font-size: 14px;
      font-weight: 600;
      margin: 30px 0px 70px;
      color: #333;
    }
    .pageColor {
      color: #f45b5b;
    }
    .divider-line {
      width: 300px;
      margin: 0 auto;
      font-size: 14px;
      color: #717171;
      span {
        font-size: 30px;
      }
    }
  }
}
</style>